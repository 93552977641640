import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import BlockContent from "@sanity/block-content-to-react"
import Iframe from "react-iframe"
import styled from "styled-components"
import Slide from "../components/slideIntro"

const Iframer = styled.div`
  margin-top: 150px;
  iframe {
    margin: 0 auto;
    display: block;
    width: 1280px;
  }
`

const IndexPage = ({ data }) => {
  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  }

  return (
    <Layout>
      <Slide />
      {/* <Iframer dangerouslySetInnerHTML={ iframe1() } /> */}
    </Layout>
  )
}

export default IndexPage

// export const query = graphql`
//   query IndexQuery {
//     allSanityBlogPost(sort: {fields: postDate, order: ASC}) {
//       edges {
//         node {
//           _rawPostContent(resolveReferences: {maxDepth: 10})
//           postDate
//           postTitle
//           postImage {
//             asset {
//               fluid(maxWidth: 700) {
//                 ...GatsbySanityImageFluid
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
